@font-face {
  font-family: 'wm-large-icons';
  src:  url('fonts/wm-large-icons.eot?dspsmj');
  src:  url('fonts/wm-large-icons.eot?dspsmj#iefix') format('embedded-opentype'),
    url('fonts/wm-large-icons.ttf?dspsmj') format('truetype'),
    url('fonts/wm-large-icons.woff?dspsmj') format('woff'),
    url('fonts/wm-large-icons.svg?dspsmj#wm-large-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wm-large-"], [class*=" wm-large-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wm-large-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wm-large-complicance-hub:before {
  content: "\e904";
}
.wm-large-trading-tools:before {
  content: "\e905";
}
.wm-large-7-support:before {
  content: "\e906";
}
.wm-large-fast-transactions:before {
  content: "\e907";
}
.wm-large-user-interface:before {
  content: "\e900";
}
.wm-large-coin-selection:before {
  content: "\e901";
}
.wm-large-low-fees:before {
  content: "\e902";
}
.wm-large-secure-protection:before {
  content: "\e903";
}
.wm-large-transparent-commission-structures:before {
  content: "\e94d";
}
.wm-large-stable-service-operations:before {
  content: "\e94e";
}
.wm-large-reserve-increase:before {
  content: "\e94f";
}
.wm-large-real-time-cryptocurrency-charts:before {
  content: "\e950";
}
.wm-large-instant-transactions:before {
  content: "\e951";
}
.wm-large-favorable-cryptocurrency-rates:before {
  content: "\e952";
}
