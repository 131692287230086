@font-face {
  font-family: 'wm-theme-icons';
  src:  url('fonts/wm-theme-icons.eot?heps1x');
  src:  url('fonts/wm-theme-icons.eot?heps1x#iefix') format('embedded-opentype'),
    url('fonts/wm-theme-icons.ttf?heps1x') format('truetype'),
    url('fonts/wm-theme-icons.woff?heps1x') format('woff'),
    url('fonts/wm-theme-icons.svg?heps1x#wm-theme-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wm-icon-"], [class*=" wm-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wm-theme-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wm-icon-about-1:before {
  content: "\e949";
}
.wm-icon-about-2:before {
  content: "\e94a";
}
.wm-icon-about-3:before {
  content: "\e94b";
}
.wm-icon-about-4:before {
  content: "\e94c";
}
.wm-icon-table:before {
  content: "\e947";
}
.wm-icon-blocks:before {
  content: "\e948";
}
.wm-icon-copy:before {
  content: "\e945";
}
.wm-icon-filter:before {
  content: "\e946";
}
.wm-icon-search:before {
  content: "\e944";
}
.wm-icon-quotes:before {
  content: "\e942";
}
.wm-icon-navigation_pin:before {
  content: "\e943";
}
.wm-icon-settings:before {
  content: "\e93f";
}
.wm-icon-iban-verification:before {
  content: "\e940";
}
.wm-icon-profile:before {
  content: "\e941";
}
.wm-icon-x:before {
  content: "\e93b";
}
.wm-icon-facebook:before {
  content: "\e93c";
}
.wm-icon-telegram:before {
  content: "\e93d";
}
.wm-icon-instagram:before {
  content: "\e93e";
}
.wm-icon-trend-down:before {
  content: "\e939";
}
.wm-icon-trend-up:before {
  content: "\e93a";
}
.wm-icon-user:before {
  content: "\e900";
}
.wm-icon-enter:before {
  content: "\e901";
}
.wm-icon-wallet:before {
  content: "\e902";
}
.wm-icon-user-interface:before {
  content: "\e903";
}
.wm-icon-trading-tools:before {
  content: "\e904";
}
.wm-icon-time:before {
  content: "\e905";
}
.wm-icon-secure-protection:before {
  content: "\e906";
}
.wm-icon-reviews:before {
  content: "\e907";
}
.wm-icon-referrals:before {
  content: "\e908";
}
.wm-icon-payment-systems:before {
  content: "\e909";
}
.wm-icon-partner-program:before {
  content: "\e90a";
}
.wm-icon-number-confirmed:before {
  content: "\e90b";
}
.wm-icon-note:before {
  content: "\e90c";
}
.wm-icon-newsletter:before {
  content: "\e90d";
}
.wm-icon-low-fees:before {
  content: "\e90e";
}
.wm-icon-logs:before {
  content: "\e90f";
}
.wm-icon-logout:before {
  content: "\e910";
}
.wm-icon-info:before {
  content: "\e911";
}
.wm-icon-help:before {
  content: "\e912";
}
.wm-icon-footer-icon:before {
  content: "\e913";
}
.wm-icon-feedback:before {
  content: "\e914";
}
.wm-icon-fast-transactions:before {
  content: "\e915";
}
.wm-icon-eye-open:before {
  content: "\e916";
}
.wm-icon-eye-close:before {
  content: "\e917";
}
.wm-icon-exchange-directions:before {
  content: "\e918";
}
.wm-icon-documents-verification:before {
  content: "\e919";
}
.wm-icon-crypto-dictionary:before {
  content: "\e91a";
}
.wm-icon-cryptocurrency-exchange:before {
  content: "\e91b";
}
.wm-icon-cryptocurrency-courses:before {
  content: "\e91c";
}
.wm-icon-contact-us:before {
  content: "\e91d";
}
.wm-icon-contacts:before {
  content: "\e91e";
}
.wm-icon-complicance-hub:before {
  content: "\e91f";
}
.wm-icon-coin-selection:before {
  content: "\e920";
}
.wm-icon-close:before {
  content: "\e921";
}
.wm-icon-clipboard:before {
  content: "\e922";
}
.wm-icon-clients:before {
  content: "\e923";
}
.wm-icon-city:before {
  content: "\e924";
}
.wm-icon-chevron-up:before {
  content: "\e925";
}
.wm-icon-chevron-right:before {
  content: "\e926";
}
.wm-icon-chevron-left:before {
  content: "\e927";
}
.wm-icon-chevron-down:before {
  content: "\e928";
}
.wm-icon-check:before {
  content: "\e929";
}
.wm-icon-card:before {
  content: "\e92a";
}
.wm-icon-buy-cryptocurrency:before {
  content: "\e92b";
}
.wm-icon-blog:before {
  content: "\e92c";
}
.wm-icon-bitcoin-wallet:before {
  content: "\e92d";
}
.wm-icon-articles:before {
  content: "\e92e";
}
.wm-icon-arrow-up:before {
  content: "\e92f";
}
.wm-icon-arrow-right-up:before {
  content: "\e930";
}
.wm-icon-arrow-right:before {
  content: "\e931";
}
.wm-icon-arrow-left-up:before {
  content: "\e932";
}
.wm-icon-arrow-left:before {
  content: "\e933";
}
.wm-icon-arrow-down:before {
  content: "\e934";
}
.wm-icon-answer-templates:before {
  content: "\e935";
}
.wm-icon-analitycs-report:before {
  content: "\e936";
}
.wm-icon-about-us:before {
  content: "\e937";
}
.wm-icon-7-support:before {
  content: "\e938";
}
