@font-face {
    font-family: 'Overpass Mono';
    src: url('OverpassMono-Regular.eot');
    src: url('OverpassMono-Regular.eot?#iefix') format('embedded-opentype'),
        url('OverpassMono-Regular.woff2') format('woff2'),
        url('OverpassMono-Regular.woff') format('woff'),
        url('OverpassMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass Mono';
    src: url('OverpassMono-SemiBold.eot');
    src: url('OverpassMono-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('OverpassMono-SemiBold.woff2') format('woff2'),
        url('OverpassMono-SemiBold.woff') format('woff'),
        url('OverpassMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass Mono';
    src: url('OverpassMono-Light.eot');
    src: url('OverpassMono-Light.eot?#iefix') format('embedded-opentype'),
        url('OverpassMono-Light.woff2') format('woff2'),
        url('OverpassMono-Light.woff') format('woff'),
        url('OverpassMono-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass Mono';
    src: url('OverpassMono-Medium.eot');
    src: url('OverpassMono-Medium.eot?#iefix') format('embedded-opentype'),
        url('OverpassMono-Medium.woff2') format('woff2'),
        url('OverpassMono-Medium.woff') format('woff'),
        url('OverpassMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overpass Mono';
    src: url('OverpassMono-Bold.eot');
    src: url('OverpassMono-Bold.eot?#iefix') format('embedded-opentype'),
        url('OverpassMono-Bold.woff2') format('woff2'),
        url('OverpassMono-Bold.woff') format('woff'),
        url('OverpassMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

